//
//
//

export default {
  name: 'Index',
  head: {
    title: 'Account Login',
    meta: [
      {
        hid: 'og:title',
        name: 'og:title',
        content: 'Account Login',
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: 'Log in to your Summit Account to access CE courses, register for additional courses and download CE certificates. CE courses include on-demand video and text, live webinar, live streams and in-person options.',
      },
      {
        hid: 'description',
        name: 'description',
        content: 'Log in to your Summit Account to access CE courses, register for additional courses and download CE certificates. CE courses include on-demand video and text, live webinar, live streams and in-person options.',
      },
    ],
  },
};
